<template>
  <v-container>
    <div class="mt-3">
      <iframe
        :src="url"
        frameborder="0"
        type="application/pdf"
        width="100%"
        height="800px"
      ></iframe>
    </div>
  </v-container>
</template>

<script>
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import moment from "moment";

export default {
  name: "nota-return",
  props: {
    id: Number,
  },
  computed: {
    total() {
      let total = 0;
      this.nota.products.forEach(x => {
        total += x.total;
      });
      return total;
    },
  },
  data() {
    return {
      url: "",
      nota: {},
    };
  },
  methods: {
    async preview() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleReturn/getNota", this.id)
        .then(async response => {
          this.nota = response.data;
          this.createPdf();
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed().replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    setNewArray(items) {
      const array = [];
      items.forEach((x, index) => {
        array.push([
          {
            content: index + 1,
            styles: { valign: "top", halign: "center" },
          },
          {
            content: `${x.mfr};${x.partNumber};${x.partDesc}`,
            styles: { valign: "top", halign: "left" },
          },
          {
            content: this.formatPrice(x.total),
            styles: { valign: "top", halign: "right" },
          },
          {
            content: this.formatPrice(x.total),
            styles: { valign: "top", halign: "right" },
          },
        ]);
      });
      return array;
    },
    createPdf() {
      const doc = new jsPDF();

      doc.setFont("Times", "bold");
      doc.setFontSize(14);
      doc.text("NOTA RETUR", 90, 10);

      doc.setFont("Times", "normal");
      doc.setFontSize(10);
      doc.text(`No :`, 10, 20);
      doc.text(`Tanggal : ${moment().format("DD MMMM YYYY")}`, 10, 25);
      doc.text(`(Atas Faktur Pajak Nomor : ${this.nota.taxSerialNumber})`, 10, 30);
      doc.text(`PO No. ${this.nota.poNumber}`, 10, 35);

      doc.setFont("Times", "bold");
      doc.setFontSize(11);
      doc.text("PEMBELI", 20, 45);
      doc.line(20, 46, 38, 46);

      doc.setFont("Times", "normal");
      doc.setFontSize(10);
      doc.text("Nama", 10, 50);
      doc.text("Alamat", 10, 55);
      doc.text("NPWP", 10, 60);
      doc.text("NPPKP", 10, 65);

      doc.text(`: ${this.nota.customerAddress}`, 50, 55);
      doc.text(`: ${this.nota.customerNpwp}`, 50, 60);
      doc.text(`: ${this.nota.customerNpwp}`, 50, 65);

      doc.setFont("Times", "bold");
      doc.setFontSize(10);
      doc.text(`: ${this.nota.customerName}`, 50, 50);

      doc.setFont("Times", "bold");
      doc.setFontSize(11);
      doc.text("KEPADA PENJUAL", 20, 75);
      doc.line(20, 76, 55, 76);

      doc.setFont("Times", "normal");
      doc.setFontSize(10);
      doc.text("Nama", 10, 80);
      doc.text("Alamat", 10, 85);
      doc.text("NPWP", 10, 90);

      doc.text(
        "Intercon Plaza Blok D.11, Jl. Meruya Ilir, Srengseng - Kembangan, Jakarta Barat",
        50,
        85
      );
      doc.text("01.789.513.7.038.000", 50, 90);
      doc.text("Tgl.Pengukuhan PKP :", 120, 90);
      doc.text("07 April 2008", 160, 90);

      doc.setFont("Times", "bold");
      doc.setFontSize(10);
      doc.text("PT.GRAHASUMBER PRIMA ELEKTRONIK", 50, 80);

      doc.autoTable({
        margin: { top: 95, left: 10 },
        theme: "plain",
        head: [["No", "Nama Barang Kena Pajak", "Dasar Pengenaan Pajak", "Harga BKP (Rp)"]],
        body: [
          ...this.setNewArray(this.nota.products),
          [
            {
              colSpan: 2,
              content: "jumlah",
              styles: { valign: "middle", halign: "right" },
            },
            {
              content: this.formatPrice(this.total),
              styles: { valign: "middle", halign: "right" },
            },
            {
              content: this.formatPrice(this.total),
              styles: { valign: "middle", halign: "right" },
            },
          ],
          [
            {
              colSpan: 2,
              content: `${this.nota.invoiceDuration} DAYS`,
              styles: { valign: "middle", halign: "left" },
            },
            {
              content: this.formatPrice(this.total),
              styles: { valign: "middle", halign: "right" },
            },
            {
              content: this.formatPrice(this.total),
              styles: { valign: "middle", halign: "right" },
            },
          ],
          [
            {
              colSpan: 3,
              content: "Jumlah Harga BKP yang dikembalikan",
              styles: { valign: "middle", halign: "left" },
            },
            {
              content: this.formatPrice(this.total),
              styles: { valign: "middle", halign: "right" },
            },
          ],
          [
            {
              colSpan: 3,
              content: "Pajak Pertambahan Nilai yang diminta kembali",
              styles: { valign: "middle", halign: "left" },
            },
            {
              content: this.formatPrice(this.total * (this.nota.ppn / 100)),
              styles: { valign: "middle", halign: "right" },
            },
          ],
        ],
        styles: {
          valign: "middle",
          lineWidth: 0.1,
          halign: "left",
          lineColor: "black",
          fontSize: 7,
          overflow: "linebreak",
          overflowColums: "linebreak",
        },
        headStyles: {
          valign: "middle",
          halign: "center", //'center' or 'right',
          fontStyle: "bold",
        },
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 120 },
          2: { cellWidth: 30 },
          3: { cellWidth: 30 },
          // etc
        },
      });

      let y = doc.lastAutoTable.finalY + 10;
      doc.setFont("Times", "bold");
      doc.setFontSize(10);
      doc.text(`Jakarta, ${moment().format("DD MMMM YYYY")}`, 120, y);
      doc.text(this.nota.customerName, 120, y + 5);

      this.url = doc.output("datauristring");
    },
  },
  created() {
    this.preview();
  },
};
</script>

<style></style>
